import { Box, ListItem, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import clsx from "clsx";
import React, { FC } from "react";
import { BUILD_ID } from "../analytics";
import InstagramIcon from "../img/instagram-icon.svg";
import LogoOnBlackSvg from "../img/logo-on-black.svg";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      color: theme.colors.white,
      backgroundColor: theme.colors.logo.darkness,
    },
    footerContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
      padding: theme.spacing(6, 4),
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "auto",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    footerList: {
      justifySelf: "center",
      margin: theme.spacing(0, 0, 3, 3),
      padding: 0,
      minWidth: "50%",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        flexShrink: 1,
        margin: 0,
        minWidth: 0,
        width: "15%",
      },
    },
    productList: {
      gridRowStart: 1,
      gridRowEnd: 3,
      marginLeft: 0,
      [theme.breakpoints.up("md")]: {
        alignSelf: "flex-start",
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(0, 2),
        width: "33%",
      },
      "& a": {
        [theme.breakpoints.up("md")]: {
          width: "50%",
        },
      },
    },
    link: {
      ...theme.typography.body1,
      color: theme.colors.white,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1.25, 0),
      width: "auto",
    },
    logoArea: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        gridColumnEnd: 4,
      },
      [theme.breakpoints.up("md")]: {
        flexGrow: 1,
        marginRight: theme.spacing(3),
        order: -1,
        padding: theme.spacing(0, 2),
        textAlign: "left",
      },
    },
    logoBtn: {
      marginBottom: theme.spacing(1),
      padding: 0,
    },
    logoSvg: {
      height: "auto",
      maxWidth: "220px",
      width: "100%",
    },
    centered: {
      display: "flex",
      justifyContent: "center",
      margin: "auto",
      width: "auto",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },
      "& a": {
        margin: theme.spacing(0, 2),
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(0, 2, 0, 0),
        },
      },
    },
    lightText: {
      margin: theme.spacing(0, 0, 1),
      opacity: 0.6,
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
    socialBtn: {
      color: theme.colors.white,
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(-1.5),
      },
    },
    instaBtn: {
      "& svg": {
        height: 20,
        width: 20,
      },
    },
    buildText: {
      wordBreak: "break-word",
    },
  }),
  { index: 3, name: "Footer" }
);

type FooterProps = {
  className?: string;
};
export const Footer: FC<FooterProps> = ({ className }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box component="footer" className={clsx(classes.root, className)}>
      <Container maxWidth="lg" disableGutters>
        <Box className={classes.footerContainer}>
          {/* Product */}
          <MenuList className={clsx(classes.footerList, classes.productList)}>
            <Typography variant="body2" className={classes.lightText}>
              Product
            </Typography>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/habits"
              title="Habits"
              target="reclaim"
            >
              Habits
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/tasks"
              title="Tasks"
              target="reclaim"
            >
              Tasks
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/smart-one-on-ones"
              title="Smart 1:1s"
              target="reclaim"
            >
              Smart 1:1s
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/calendar-sync"
              title="Calendar Sync"
              target="reclaim"
            >
              Calendar Sync
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/buffer-time"
              title="Buffer Time"
              target="reclaim"
            >
              Buffer Time
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/slack-integration"
              title="Slack Integration"
              target="reclaim"
            >
              Slack Integration
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/features/zoom-integration"
              title="Zoom Integration"
              target="reclaim"
            >
              Zoom Integration
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/use-cases"
              title="Use Cases"
              target="reclaim"
            >
              Use Cases
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/pricing"
              title="Pricing"
              target="reclaim"
            >
              Pricing
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/security"
              title="Security"
              target="reclaim"
            >
              Security
            </ListItem>
          </MenuList>

          {/* Company */}
          <MenuList className={classes.footerList}>
            <Typography variant="body2" className={classes.lightText}>
              Company
            </Typography>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/about"
              title="About"
              target="reclaim"
            >
              About
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/careers"
              title="Careers"
              target="reclaim"
            >
              Careers
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/blog"
              title="Habits"
              target="reclaim"
            >
              Blog
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="mailto:hello@reclaim.ai"
              title="Contact"
              target="reclaim"
            >
              Contact
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//calendly.com/reclaim-founders/reclaim-office-hours"
              title="Meet with Us"
              target="reclaim"
            >
              Meet with Us
            </ListItem>
          </MenuList>

          {/* Support */}
          <MenuList className={classes.footerList}>
            <Typography variant="body2" className={classes.lightText}>
              Support
            </Typography>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//help.reclaim.ai/en/articles/5341864-how-do-i-contact-support-to-get-help"
              title="Contact Support"
              target="reclaim"
            >
              Contact Support
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//help.reclaim.ai"
              title="Help Docs"
              target="reclaim"
            >
              Help Docs
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//updates.reclaim.ai"
              title="Change Log"
              target="reclaim"
            >
              Change Log
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//status.reclaim.ai"
              title="Status"
              target="reclaim"
            >
              Status
            </ListItem>
            <ListItem
              disableGutters
              className={classes.link}
              component="a"
              href="//reclaim.ai/responsible-disclosure"
              title="Responsible Disclosure Policy"
              target="reclaim"
            >
              Responsible Disclosure Policy
            </ListItem>
          </MenuList>

          {/* Branding */}
          <Box className={classes.logoArea}>
            <IconButton
              href="//reclaim.ai"
              size="medium"
              aria-label="reclaim.ai"
              disableRipple
              disableFocusRipple
              className={classes.logoBtn}
            >
              <LogoOnBlackSvg className={classes.logoSvg} preserveAspectRatio="xMidYMid meet" />
            </IconButton>
            <MenuList className={clsx(classes.footerList, classes.centered)}>
              <ListItem
                disableGutters
                className={classes.link}
                component="a"
                href="//reclaim.ai/terms"
                title="Terms of use"
                target="reclaim"
              >
                Terms of use
              </ListItem>
              <ListItem
                disableGutters
                className={classes.link}
                component="a"
                href="//reclaim.ai/privacy"
                title="Privacy Policy"
                target="reclaim"
              >
                Privacy Policy
              </ListItem>
            </MenuList>
            <Typography variant="body2" className={classes.lightText}>
              &copy; 2021 Reclaim.ai, Inc.
            </Typography>

            {/* build id */}
            <Typography variant="body2" className={clsx(classes.lightText, classes.buildText)}>
              Build: {BUILD_ID}
            </Typography>

            {/* socials */}
            <IconButton
              className={classes.socialBtn}
              href="https://twitter.com/reclaimai"
              size="medium"
              aria-label="twitter"
              target="reclaim-social"
              color="inherit"
            >
              <TwitterIcon color="inherit" />
            </IconButton>
            <IconButton
              className={classes.socialBtn}
              href="https://www.linkedin.com/company/reclaim-ai/"
              size="medium"
              aria-label="linkedin"
              target="reclaim-social"
              color="inherit"
            >
              <LinkedInIcon color="inherit" />
            </IconButton>
            <IconButton
              className={clsx(classes.socialBtn, classes.instaBtn)}
              href="https://www.instagram.com/reclaimai/"
              size="medium"
              aria-label="instagram"
              target="reclaim-social"
              color="inherit"
            >
              <InstagramIcon color="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
